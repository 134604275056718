import React, {useEffect} from 'react';
import styled, {ThemeProvider as ScThemeProvider} from 'styled-components';
import {jssPreset, StylesProvider, ThemeProvider as MuiThemeProvider} from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {create} from "jss";
import Search from './Search'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import {
    Close as CloseIcon,
    Search as SearchIcon
} from "@material-ui/icons";
import {fetchProducts} from "../redux/products/products.actions";
import {useDispatch} from "react-redux";

const MainContentView = styled.div`
 color: white;

`;

const StyledAppBar = styled(AppBar)`
   position: relative;
   height: 8.8rem;
`;

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
      background-color: rgba(0,0,0,.7) !important;
    }
`;

const darkThemeOptions = {
    type: "dark",
    palette: {
        primary: {
            light: "#212121",
            main: "#d0d0d0",
            dark: "#2c2c2c",
            text: "#d0d0d0",
            contrastText: "#b9b9b9",
        },
        secondary: {
            light: "#212121",
            main: "#171717",
            dark: "#151515",
            text: "#d0d0d0",
            contrastText: "#d0d0d0",
        },
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
    const theme = createMuiTheme(darkThemeOptions);
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    React.useEffect(()=>{
        window.openSearch = function () {
            setOpen(true);
        }
    },[])

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    return (
        <StylesProvider jss={create({plugins: [...jssPreset().plugins]})}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <ScThemeProvider theme={theme}>
                        <MainContentView className={"MainContentView"}>
                            <SearchIcon className={"searchIcon"} onClick={handleClickOpen} style={{fill: '#BDBDBD', cursor: "pointer", fontSize:"2.6rem"}}/>
                            <StyledDialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                                <StyledAppBar>
                                    <Toolbar onClick={handleClose}>
                                        <CloseIcon style={{fill: '#9e000f', cursor: "pointer", fontSize:"5.6rem"}} onClick={handleClose}/>
                                    </Toolbar>
                                </StyledAppBar>
                                <Search style={{marginTop: "100px"}}/>
                            </StyledDialog>
                        </MainContentView>
                    </ScThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </StylesProvider>
    );
}

export default App;
