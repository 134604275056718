import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {useVisible} from 'react-hooks-visible'
import {CircularProgress} from '@material-ui/core'
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import styled from "styled-components";

export const Wrapper = styled.div`
    text-align: center;
    margin: 0 1rem 2rem 1rem;
    
    text-decoration: unset;
        width: 320px;
        height: 380px;
        .itemImage {
         width: 100%;
    }
    
    .itemText {
      margin-top: 1rem;
    }
    
    & > a {
     color: white;
     text-decoration: unset;
    }
`;

export default function Product({product}) {
    const [targerRef, visible] = useVisible();
    const [watched, setWatched] = useState(false)

    useEffect(() => {
        if (visible) {
            setWatched(true);
        }
    }, [visible])

    return (
        <>
            <Grow
                in={true}
                style={{transformOrigin: '0 0 0'}}
                {...({timeout: 1000})}
            >
                {<Wrapper ref={targerRef}>
                    {!watched && <CircularProgress style={{marginTop: '50%'}}/>}
                    <Fade in={watched} {...({timeout: 1000})}>
                        <a href={product.url} style={{display: watched ? 'block' : 'none'}}>
                            <div>
                                <img className={"itemImage"} src={product["defaultImageUrl"]} alt={product["name"]}/>
                                <div className={"itemText"}>
                                    <Typography variant={"button"}
                                                style={{fontWeight: "bolder", fontSize: "1.8rem"}}>{product["name"]} </Typography>
                                </div>
                            </div>
                        </a>
                    </Fade>
                </Wrapper>
                }
            </Grow>
        </>
    )
}
