const axios = require('axios').default;
const LOCAL_MODE = false;

const API_KEY = "ZRSLF1P9DGP21YNRVUX1BV1QDL2X8E27";
const API_HOSTNAME = "mrsmithjewelry.com";
const LOCAL_PATH = 'http://localhost:8080/api';
const API_PATH = LOCAL_MODE ? LOCAL_PATH : `https://${API_HOSTNAME}/api`;
const SEARCH_PATH = `${API_PATH}/search`;
const PRODUCTS_PATH = `${API_PATH}/products`;
const output_format = "JSON";


function shuffle(array) {
    array.sort(() => Math.random() - 0.5);
    return array;
}

// return shuffled products as promise
export function fetchProductsRequest(language = 1) {
    const params = {
        language,
        output_format,
        display: "full",
        'Content-Type': 'application/json',
        'ws_key':API_KEY // TODO :  aviv this is the solution
    };
    if (!LOCAL_MODE) {
        params["filter[active]"] = 1;
    }
    return axios.get(PRODUCTS_PATH, {params}).then(res => {
        return shuffle(res.data["products"].map(product => {
            product["defaultImageUrl"] = `https://mrsmithjewelry.com/${product["id_default_image"]}-large_default/${product["link_rewrite"]}.jpg`;
            product["url"] = `https://mrsmithjewelry.com/${product["id"]}-${product["link_rewrite"]}.html`;
            return product;
        })) || [];
    });
}

// return product ids as promise
export function fetchSearchProductsRequest({term, language = 1, onSuccess}) {
    return axios.get(SEARCH_PATH, {
        params: {
            query: term,
            language,
            output_format,
            'Content-Type': 'application/json',
            'ws_key':API_KEY
        }
    }).then(res => {
        return (res.data["products"] && res.data["products"].map(({id}) => id)) || [];
    });
}
