import React, {useState, useEffect} from 'react';
import Listing from './Listing'
import {useDebounce} from '../hooks/useDebounce'
import {useDispatch, useSelector} from 'react-redux';
import {fetchSearchProducts, showAllProducts} from '../redux/products/products.actions'
import styled from "styled-components";
import {TextField} from "@material-ui/core";
import {getSearchTerm} from "../redux/products/products.selectors";

const StyledSearchWrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-inputTypeSearch{
    color:white;
    text-align:center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 2.5rem;
    padding: 1% 0 0.5% 1.8%;
    font-weight: 300;
    text-transform: uppercase;
  }
  .MuiInputBase-root {
    line-height: unset;
  }
`;

const Wrapper = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default function Search() {
    const dispatch = useDispatch();
    const [term, setTerm] = useState(useSelector(getSearchTerm));
    const debouncedSearchTerm = useDebounce(term, 300);

    useEffect(() => {
        if (!debouncedSearchTerm || debouncedSearchTerm === "") {
            dispatch(showAllProducts())
        } else {
            dispatch(fetchSearchProducts(debouncedSearchTerm));
        }
    }, [debouncedSearchTerm, dispatch]);

    return (
        <Wrapper>
            <StyledSearchWrapper>
                <div style={{textAlign: 'center'}}>
                    <StyledTextField
                        className={"textField"} fullWidth
                        id="react-search"
                        placeholder="ENTER YOUR KEYWORDS"
                        type="search"
                        defaultValue={term}
                        onChange={(e) => setTerm(e.target.value)}
                        autoComplete='off'
                    />
                </div>
            </StyledSearchWrapper>
            <Listing/>
        </Wrapper>
    )
}
