import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {Provider} from "react-redux";
import './index.css';
import store, {persistor} from "./redux/store";
import {PersistGate} from 'redux-persist/lib/integration/react';


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<div>Loading app</div>} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>,
    document.getElementById('root-search'));