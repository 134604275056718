import React from 'react';
import Product from './Product'
import {getProducts, getIsLoading, getSearchTerm} from '../redux/products/products.selectors'
import {useSelector} from 'react-redux';
import {CircularProgress} from '@material-ui/core';
import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
`;

export default function Listing() {
    const isLoading = useSelector(getIsLoading);
    const products = useSelector(getProducts);
    const isPresent = products && Array.isArray(products) && products.length > 0;
    const searchTerm = useSelector(getSearchTerm);
    return (
        <FlexBox>
            {isLoading && <CircularProgress/>}
            {!isLoading && isPresent && products.map(product => <Product product={product} key={product.id}/>)}
            {!isLoading && !isPresent &&
            <div style={{
                color: "white", fontFamily: "Roboto, Helvetica, Arial, sans-serif"
            }}><h1>No records found</h1><h3>There is no results matching you search
                "{searchTerm}".</h3></div>}
        </FlexBox>
    )
}