import {
    SHOW_ALL_PRODUCTS,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_REQUEST,
    FETCH_SEARCH_PRODUCTS_REQUEST,
    FETCH_SEARCH_PRODUCTS_SUCCESS,
} from '../products/products.actions'


const INITAL_STATE = {
    ids: [],
    map: {},
    isLoading: false,
    searchTerm: "",
};

export function productsReducer (state = INITAL_STATE,{type,payload}){
    switch(type){
        case FETCH_PRODUCTS_SUCCESS: {
            return {
                ...state,
                ids: payload.map(product=>product.id),
                map: payload.reduce((prev, curr)=> {
                    prev[curr.id] = curr;
                    return prev;
                },{}),
                isLoading: false
            };
        }
        case FETCH_PRODUCTS_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case FETCH_SEARCH_PRODUCTS_SUCCESS: {
            return {
                ...state,
                ids: payload,
                isLoading: false
            };
        }

        case FETCH_SEARCH_PRODUCTS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                searchTerm: payload.term
            };
        }


        case SHOW_ALL_PRODUCTS: {
            return {
                ...state,
                ids: Object.keys(state.map),
                searchTerm: "",
            }
        }
        default: {
            return state;
        }
    }
}