import { fetchProductsRequest, fetchSearchProductsRequest } from '../../services/searchService'

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

export const FETCH_SEARCH_PRODUCTS_REQUEST = "FETCH_SEARCH_PRODUCTS_REQUEST";
export const FETCH_SEARCH_PRODUCTS_SUCCESS = "FETCH_SEARCH_PRODUCTS_SUCCESS";

export const SHOW_ALL_PRODUCTS = "SHOW_ALL_PRODUCTS";

export const fetchProducts = () => async dispatch => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
    const payload = await fetchProductsRequest();
    dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload });
};

export const fetchSearchProducts = term => async dispatch => {
    dispatch({ type: FETCH_SEARCH_PRODUCTS_REQUEST, payload: {term} });
    const payload = await fetchSearchProductsRequest({ term });
    dispatch({ type: FETCH_SEARCH_PRODUCTS_SUCCESS, payload });
};

export const showAllProducts = () => ({ type: SHOW_ALL_PRODUCTS});